/* eslint-disable react/prop-types */
import * as React from 'react';
import { FaHeartCrack } from 'react-icons/fa6';

export const Regret = ({ style }) => (
    <FaHeartCrack
        style={{
            color: '#795548',
            fontSize: '1.2em',
            verticalAlign: 'sub',
            paddingRight: '0.5em',
            ...style,
        }}
        title="Regret"
    />
);
