/* eslint-disable react/prop-types */
import * as React from 'react';
import { FaHeart } from 'react-icons/fa6';

export const Like = ({ style }) => (
    <FaHeart
        style={{
            color: '#f47e20',
            fontSize: '1.2em',
            verticalAlign: 'sub',
            paddingRight: '0.5em',
            ...style,
        }}
        title="Like"
    />
);
