import { StarRating } from "../../../../content/blog/2025-02-03-open-source-crm-benchmark-for-2025/components/StarRating";
import { Plus } from "../../../../content/blog/2025-02-03-open-source-crm-benchmark-for-2025/components/Plus";
import { Minus } from "../../../../content/blog/2025-02-03-open-source-crm-benchmark-for-2025/components/Minus";
import { Love } from "../../../../content/blog/2025-02-03-open-source-crm-benchmark-for-2025/components/Love";
import { Like } from "../../../../content/blog/2025-02-03-open-source-crm-benchmark-for-2025/components/Like";
import { Regret } from "../../../../content/blog/2025-02-03-open-source-crm-benchmark-for-2025/components/Regret";
import React from 'react';
export default {
  StarRating,
  Plus,
  Minus,
  Love,
  Like,
  Regret,
  React
};