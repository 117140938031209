/* eslint-disable react/prop-types */
import * as React from 'react';
import { FaMinusCircle } from 'react-icons/fa';

export const Minus = ({ style }) => (
    <FaMinusCircle
        style={{
            color: '#E4002A',
            fontSize: '1.2em',
            verticalAlign: 'sub',
            paddingRight: '0.5em',
            ...style,
        }}
        title="Minus"
    />
);
