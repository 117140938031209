/* eslint-disable react/prop-types */
import * as React from 'react';
import { FaStar, FaRegStar } from 'react-icons/fa';

export const StarRating = ({ rating, style }) => {
    const stars = Array.from({ length: 5 }, (_, index) => {
        if (index < rating) {
            return <FaStar key={index} />;
        }
        return <FaRegStar key={index} />;
    });

    return (
        <span
            style={{
                color: '#f57c01',
                fontSize: '1.2em',
                verticalAlign: 'middle',
                ...style,
            }}
        >
            {stars}
        </span>
    );
};
