/* eslint-disable react/prop-types */
import * as React from 'react';
import { FaFaceGrinHearts } from 'react-icons/fa6';

export const Love = ({ style }) => (
    <FaFaceGrinHearts
        style={{
            color: '#4CAF50',
            fontSize: '1.2em',
            verticalAlign: 'sub',
            paddingRight: '0.5em',
            ...style,
        }}
        title="Love"
    />
);
